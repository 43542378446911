import { configureStore } from "@reduxjs/toolkit";
import currentConsultReducer from "../../redux/slices/currentConsultSlice";
import refreshReducer from "../../redux/slices/refreshSlice";

export default configureStore({
    reducer: {
        isFresh: refreshReducer,
        currentConsult: currentConsultReducer,
    },
});
