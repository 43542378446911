import pino from "pino";

const isDev = process.env.ENV_SLUG === "dev";

export const logger = pino({
    browser: {},
    level: process.env.LOG_LEVEL || "info",
    base: {
        env: process.env.NODE_ENV,
    },
    transport: {
        target: "pino-pretty",
        options: {
            colorize: isDev,
            singleLine: true,
        },
    },
});

export const debug = function (title: string, description: string, opts?: any) {
    if (!isDev) {
        return;
    }
    if (!opts) {
        opts = {};
    }
    logger.info(`[${title}]`);
    if (opts) {
        logger.info(
            `${description}(${Object.keys(opts)
                .map((key) => key)
                .join(", ")})`
        );
        logger.info(opts);
    } else {
        logger.info(description);
    }
};
