import {createSlice} from '@reduxjs/toolkit'

export const refreshSlice = createSlice({
    name: 'isFresh',
    initialState: {
        value: true,
    },
    reducers: {
        setFreshFalse: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = false
        },
        setFreshTrue: (state) => {
            state.value = true
        }
    },
})

// Action creators are generated for each case reducer function
export const {setFreshFalse, setFreshTrue} = refreshSlice.actions

export default refreshSlice.reducer
