/* tslint:disable */
/* eslint-disable */
/**
 * SteadyMD Partner API
 * The Tinkerbell API allows you to get AI generated summary for consults
 *
 * The version of the OpenAPI document: 1.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface SummaryRequest
 */
export interface SummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof SummaryRequest
     */
    'consultGuid': string;
    /**
     * 
     * @type {string}
     * @memberof SummaryRequest
     */
    'summaryConfigSlug': string;
    /**
     * 
     * @type {string}
     * @memberof SummaryRequest
     */
    'summaryResultGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryRequest
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryRequest
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface SummaryResult
 */
export interface SummaryResult {
    /**
     * 
     * @type {string}
     * @memberof SummaryResult
     */
    'guid': string;
    /**
     * 
     * @type {string}
     * @memberof SummaryResult
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryResult
     */
    'consultGuid': string;
}

/**
 * SummaryRequestApi - axios parameter creator
 * @export
 */
export const SummaryRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SummaryRequest} summaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryRequestCreate: async (summaryRequest: SummaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'summaryRequest' is not null or undefined
            assertParamExists('summaryRequestCreate', 'summaryRequest', summaryRequest)
            const localVarPath = `/clinician-api/summary-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(summaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SummaryRequestApi - functional programming interface
 * @export
 */
export const SummaryRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SummaryRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SummaryRequest} summaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summaryRequestCreate(summaryRequest: SummaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummaryRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summaryRequestCreate(summaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SummaryRequestApi - factory interface
 * @export
 */
export const SummaryRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SummaryRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {SummaryRequest} summaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryRequestCreate(summaryRequest: SummaryRequest, options?: any): AxiosPromise<SummaryRequest> {
            return localVarFp.summaryRequestCreate(summaryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SummaryRequestApi - object-oriented interface
 * @export
 * @class SummaryRequestApi
 * @extends {BaseAPI}
 */
export class SummaryRequestApi extends BaseAPI {
    /**
     * 
     * @param {SummaryRequest} summaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryRequestApi
     */
    public summaryRequestCreate(summaryRequest: SummaryRequest, options?: AxiosRequestConfig) {
        return SummaryRequestApiFp(this.configuration).summaryRequestCreate(summaryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SummaryResultApi - axios parameter creator
 * @export
 */
export const SummaryResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryResultRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('summaryResultRetrieve', 'guid', guid)
            const localVarPath = `/clinician-api/summary-result/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SummaryResultApi - functional programming interface
 * @export
 */
export const SummaryResultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SummaryResultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summaryResultRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummaryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summaryResultRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SummaryResultApi - factory interface
 * @export
 */
export const SummaryResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SummaryResultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryResultRetrieve(guid: string, options?: any): AxiosPromise<SummaryResult> {
            return localVarFp.summaryResultRetrieve(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SummaryResultApi - object-oriented interface
 * @export
 * @class SummaryResultApi
 * @extends {BaseAPI}
 */
export class SummaryResultApi extends BaseAPI {
    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryResultApi
     */
    public summaryResultRetrieve(guid: string, options?: AxiosRequestConfig) {
        return SummaryResultApiFp(this.configuration).summaryResultRetrieve(guid, options).then((request) => request(this.axios, this.basePath));
    }
}


