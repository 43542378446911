// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN = publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN || "https://c368b70b1ad14636aefee91d7a90e799@o1171502.ingest.sentry.io/6270043";
const ENV_SLUG = publicRuntimeConfig.NEXT_PUBLIC_ENV_SLUG || "dev";

if (ENV_SLUG !== "dev") {
    Sentry.init({
        dsn: SENTRY_DSN,
        // Adjust this value in production, or use tracesSampler for greater control
        environment: ENV_SLUG,
        tracesSampleRate: 0.001,

        // Setting this option to true will print useful information to the console while you're setting up Sentry.
        debug: ENV_SLUG === "dev" || ENV_SLUG === "camp" || ENV_SLUG === "staging",

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        // replaysSessionSampleRate: 0.1,

        beforeSend(event, hint) {
            console.log("Sentry is sending an client event:", event);
            return event;
        },

        // You can remove this option if you're not planning to use the Sentry Session Replay feature:
        integrations: [
            new Integrations.BrowserTracing(),
            // new Sentry.Replay({
            //   // Additional Replay configuration goes in here, for example:
            //   maskAllText: true,
            //   blockAllMedia: true,
            // }),
        ],
    });
}
