import { logger } from "../logger/logger";

export function getAuthorization(req: any) {
    const blocked = _blockRequestDueToCloudflare(req);
    if (blocked) {
        throw "blocked by cloudflare";
    }
    const jwt = getToken(req);
    logger.info({ jwt }, "JWT should be set here");
    let headers: any = {
        Authorization: `Bearer ${jwt}`,
    };
    logger.info(headers, "and these are our headers");
    const cloudflareHeader = process.env.CLOUDFLARE_HEADER;
    if (cloudflareHeader && req.headers[cloudflareHeader] !== undefined) {
        headers[cloudflareHeader] = req.headers[cloudflareHeader];
    }
    logger.info(headers, "and these are our headers after cloudflare checks");
    return headers;
}

export function _blockRequestDueToCloudflare(req: any) {
    const cloudflareHeader = process.env.CLOUDFLARE_HEADER;
    if (cloudflareHeader && req.headers[cloudflareHeader] !== undefined) {
        logger.info("[CLOUDFLARE HEADER PRESENT]");
        if (req.headers[cloudflareHeader] === process.env.CLOUDFLARE_HEADER_EXPECTED_VALUE) {
            logger.info("[CLOUDFLARE HEADER PRESENT MATCHES]");
            return false;
        } else {
            logger.info("[CLOUDFLARE HEADER PRESENT DOESN'T MATCH]");
        }
    } else {
        logger.info("[CLOUDFLARE HEADER NOT PRESENT]");
    }
    return process.env.CLOUDFLARE_HEADER_BLOCK == "True";
}

export function getToken(req: any): string {
    const sessionCookie = req.cookies["__smd-clinician-portal.session-token"];
    logger.debug({ sessionCookie, url: req.url, rawHeaders: req.rawHeaders, cookies: req.cookies }, "helpers.getToken");
    return sessionCookie;
}

export default {
    getAuthorization,
    getToken,
};
